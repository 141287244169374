import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import MultiLineTextField from '../../MultiLineTextField';
import TypographyDemo from '../../../Common/TypographyDemo';
import axios from 'axios';
import { BaseURL } from '../../../../constants/Baseurl';
import { Authorization_header } from '../../../../utils/helper/Constant';
import noSurveys from '../../../../assets/No surveys (2).webp';

const styles = {
    paperStyle: {
        boxShadow: "0px 3px 6px #0000001F",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "20px",
        margin: "auto",
        maxWidth: "90%",
        width: 1200,
        height: "800",
        position: "absolute",
        transform: "translate(-0%, -0%)",
    },
    titleStyle: {
        mx: -6,
        textAlign: "left",
        fontWeight: 600,
        fontSize: "13px",
    },
    buttonStyle: {
        mr: 1,
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#9F9F9F",
        "&:hover": { backgroundColor: "#9F9F9F" },
    },
    uploadButtonStyle: {
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#00A398",
        "&:hover": { backgroundColor: "#00A398" },
    },
    modalStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonBox: {
        mt: 1,
        display: "flex",
        justifyContent: "flex-end",
        px: 2,
        mb: 2,
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #E4E4E4",
    },
    flexBoxItem: {
        display: "flex",
        justifyContent: "space-between",
        mt: 1,
        gap: 2,
        px: 2,
    },
    label: {
        color: "#404040",
        fontSize: "14px",
    },
    inputBase: {
        borderRadius: "20px",
        height: "40px",
        border: "1px solid #E4E4E4",
        pl: 1,
        mb: 0.5,
    },
    expandMoreIcon: {
        borderRadius: "50%",
        fontSize: "15px",
        backgroundColor: "#404040",
        color: "white",
        mr: 1,
        transition: "transform 0.3s ease",
    },
    sectionStyle: { fontWeight: 600, px: 2, cursor: "pointer" },
    box1Style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        borderBottom: "1px solid #E4E4E4",
        alignItems: "center",
    },
    radioStyle: {
        "& .MuiSvgIcon-root": {
            fontSize: 20,
            color: "#00A398",
        },
    },
    labelStyle: { fontSize: "13px", fontWeight: 600 },
    divStyle: {
        display: "flex",
        padding: "0 20px",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    divTitleStyle: { fontSize: "13px", fontWeight: "600" },
    selStyle: {
        border: "1px solid #E4E4E4",
        px: 2,
        py: 0.5,
        borderRadius: "20px",
        fontSize: "13px",
        width: "180px",
        textAlign: "center",
    },
};

const SurveyModal = ({ open, handleClose, projectId }) => {
    const [isVisible, setIsVisible] = useState(open);
    const [questionAnswer, setQuestionAnswer] = useState([]);
    const [showSurveyDetail, setShowSurveyDetail] = useState(false);
    const [surveyDetails, setSurveyDetails] = useState({
        surveySentTo: "",
        surveySentOn: "",
        surveyStatus: "",
        surveyResponseDate: "",
        lastReminderDate: "",
        questionAnswer: "",
        surveyCode: "",
        projectName: "",
        projectIdentifier: "",
    });

    useEffect(() => {
        setIsVisible(open);
    }, [open]);
    const formattedReminderDate = surveyDetails.lastReminderDate?.replace(/Z$/, "");
    const formattedResponseDate = surveyDetails.surveyResponseDate?.replace(/Z$/, "");
    const formattedSentDate = surveyDetails.surveySentOn?.replace(/Z$/, "");
    const [loading, setLoading] = useState(false);

    const handleAnswerChange = (e, index) => {
        const newAnswers = [];
        newAnswers[index].ans = e.target.value;
    };

    const getSurveyDetails = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams();
        try {
            queryParams.append("projectId", projectId);
            const res = await axios.get(`${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/dummy/surveydetails?${queryParams}`, Authorization_header());
            if (!res?.data?.data) {
                setLoading(false);
                setShowSurveyDetail(false);
                return;
            } else {
                setShowSurveyDetail(true);
            }
            const surveyDetails = res?.data?.data?.surveyDetails;
            setSurveyDetails({
                surveySentTo: surveyDetails?.sentTo,
                surveySentOn: surveyDetails?.sendOn,
                surveyStatus: surveyDetails?.status,
                surveyResponseDate: surveyDetails?.responseDate,
                lastReminderDate: surveyDetails?.lastReminderDate,
                surveyCode: surveyDetails?.surveyCode,
                projectName: surveyDetails?.projectName,
                projectIdentifier: surveyDetails?.projectIdentifier,
                questionAnswer: res?.data?.data?.questionAnswer,
            });
            setQuestionAnswer(res?.data?.data?.questionAnswer)
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isVisible) {
            getSurveyDetails();
        }
    }, [projectId, isVisible]);

    // const questionAnswer = [
    //     {
    //         "questionId": "752392a5-21b4-11ef-b0d5-6045bda9b613",
    //         "question": "What is the main field of science or technology?",
    //         "info": "Provide a brief description of the field of science or technology that the project relates to.The following terminology will help you complete the description.Science is the systematic study of the nature and behaviour of the physical and material universe.Work in the arts, humanities and social sciences, including economics, is not science for this purpose.From 1 April 2023 mathematical advances in themselves are treated as science for these purposes, whether or not they are advances in representing the nature and behaviour of the physical and material universe.",
    //         "answer": "Salesforce Commerce Cloud (Composable) & Amplience CMS",
    //         "lastSaved": "2024-10-08T13:44:42.000Z",
    //         "sequence": 1
    //     },
    //     {
    //         "questionId": "752395b3-21b4-11ef-b0d5-6045bda9b613",
    //         "question": "What was the baseline level of science or technology that the company planned to advance?",
    //         "info": "Describe the level of knowledge or capability that existed at the time the project started and which the company intended to advance, for example if the intention was to.Improve an existing material or device, what were its existing features and capabilities.Develop new knowledge in a particular area of science or technology, what was already known.",
    //         "answer": "Creating an ecommerce website based on composable SFCC, paired with Amplience is still relatively rare, and a first for BORN.",
    //         "lastSaved": "2024-10-08T13:44:42.000Z",
    //         "sequence": 2
    //     },
    //     {
    //         "questionId": "75239705-21b4-11ef-b0d5-6045bda9b613",
    //         "question": "What advance in that scientific or technological knowledge did the company aim to achieve?",
    //         "info": "Provide a description of the advance using the baseline level of science or technology in the previous answer as a comparison.The following information will help you complete the description.An advance in knowledge or capability in science or technology may have physical consequences or may be an increase in overall knowledge, but in either case, a competent professional working in that field would recognise it as an appreciable improvement.The improvements may include.Creating a process, material, device, product or service that increases overall knowledge or capability in a field of science or technology.Appreciably improving an existing process, material, device, product or service, for example to save costs or reduce waste (this refers to genuine non-trivial improvements which result in more than a minor or routine upgrade).Using science or technology to copy the effect of a current process, material, device, product or service in a new or improved way.",
    //         "answer": "To deliver our first website with that mix of technologies",
    //         "lastSaved": "2024-10-08T13:44:42.000Z",
    //         "sequence": 3
    //     },
    //     {
    //         "questionId": "752397c7-21b4-11ef-b0d5-6045bda9b613",
    //         "question": "What are the scientific or technological uncertainties that the company faced?",
    //         "info": "You must include only scientific or technological uncertainties, for example, the company.Does not know if it is possible to create or improve the product or process.Cannot readily deduce how to create or improve the product or process, for example you tried to build a medical diagnostic tool combining non-invasive testing technology, artificial intelligence (AI) and portability, but you’re not sure about the method to do this.Your description of the uncertainties should explain.What is stopping you from achieving the advance in scientific or technological knowledge (a problem is not an uncertainty if it can be resolved in a discussion with peers).Why is it a technological or scientific uncertainty for the industry, not just your company.If a competent professional working in the field would be uncertain as to how to achieve the advance, and explain why.",
    //         "answer": "Lack of knowledge within BORN with regard to Amplience. Lack of knowledge within the industry about how to pair these technologies to work well together",
    //         "lastSaved": "2024-10-08T13:44:42.000Z",
    //         "sequence": 4
    //     },
    //     {
    //         "questionId": "7523986b-21b4-11ef-b0d5-6045bda9b613",
    //         "question": "How did your project seek to overcome these uncertainties?",
    //         "info": "Provide more details about the direct R&D activities that try to resolve the scientific or technological uncertainties, as well as qualifying indirect activities.Describe the information in sufficient detail to tell us.How the R&D project was not straightforward.The methods planned or used to overcome the uncertainty.If the uncertainties were resolved, if yes how, and if no why not.Activities which directly contribute to R&D may include.Creating or adapting software, materials or equipment needed to resolve the uncertainty.Planning activities such as a detailed plan of how you will carry out the project.Designing, testing and analysis to resolve the scientific or technological uncertainty.",
    //         "answer": "Detailed planning amongst the technical teams at SFCC, Amplience and BORN - determining where boundaries for the project are formed. In depth training with Amplience tech team. Detailed deployment planning and preparation designed to avoid risk when launching the site. Elevated levels of post deployment support - which did lead to uncovering an issue (which could only happen in the production environment) and subsequent resolution. ",
    //         "lastSaved": "2024-10-08T13:44:42.000Z",
    //         "sequence": 5
    //     }
    // ]

    return isVisible && (
        <Modal open={open} onClose={handleClose}
            sx={styles.modalStyle}
            id="update-modal" >
            <Paper sx={ showSurveyDetail ? {...styles.paperStyle} : { ...styles.paperStyle, width: "800px" }}>
                <Box sx={{ ...styles.box1Style, py: 1 }}>
                    <IconButton onClick={handleClose} sx={{ position: "absolute", top: "5px", right: "5px" }} aria-label="close">
                        <Close />
                    </IconButton>
                    <Box sx={showSurveyDetail ? { display: "flex", flexDirection: "column", width: "90%" } : {display: "flex", flexDirection: "column", width: "90%"}}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", fontSize: "30px" }}>
                            <Typography variant="h6" sx={{ ...styles.titleStyle, fontSize: "20px" }}>
                                Survey
                            </Typography>
                        </Box>
                        {loading ? <TypographyDemo /> : !showSurveyDetail ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px", flexDirection: "column", marginTop: "30px" }}>
                                <img src={noSurveys} alt="No Surveys Available" style={{ width: "50%", borderRadius: "20px" }} />
                                
                            </Box>
                        ) : <>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 25px" }}>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Project ID - <span style={{ color: "#FD5707" }}>{surveyDetails.projectIdentifier}</span></Typography>
                                    <Typography sx={{ fontSize: "15px", color: "black", fontWeight: "500" }}>Project Name - <span style={{ color: "#FD5707" }}>{surveyDetails.projectName}</span></Typography>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Last Reminder Sent Date - <span style={{ color: "#29B1A8" }}>{formattedReminderDate}</span></Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 25px" }}>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Survey sent to - <span style={{ color: "#29B1A8" }}> {surveyDetails.surveySentTo} </span></Typography>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Survey Responded Date - <span style={{ color: "#29B1A8" }}>{formattedResponseDate}</span></Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 25px" }}>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Survey Status - <span style={{ color: "#29B1A8" }}>{surveyDetails.surveyStatus}</span></Typography>
                                    <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>Survey sent on - <span style={{ color: "#29B1A8" }}>{formattedSentDate}</span></Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}>
                                {questionAnswer.map((qn, index) => (
                                    <Box sx={{ display: "flex", flexDirection: "column", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", marginBottom: "10px", padding: "10px", borderRadius: "5px" }} key={qn.questionId}>
                                        <Box width={"95%"} sx={{ margin: "10px" }}>{index + 1}. {qn.question}</Box>
                                        <Box width={"95%"}>
                                            <MultiLineTextField
                                                value={qn.answer}
                                                onChange={(e) => handleAnswerChange(e, index)}
                                                width="10rem"
                                            />
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </>}
                    </Box>
                </Box>
            </Paper>
        </Modal>
    )
}

export default SurveyModal